<template>
  <div class="home">
    Enter Valid address
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
